<template>
  <div id="purchase-page" :class="`page ${$route.meta.headerType}`">
    <div class="container">
      <div class="page-header">
        <h1 class="title">{{ $__t('결제정보') }}</h1>
        <!-- <p class="secondary">{{ $__t("") }}</p> -->
      </div>
      <div class="page-body">
        <ui-form v-if="order" ref="form" @submit="submitForm" :formData="formData" :ignoreSubmit="true">
          <div class="section reserve">
            <div class="section-header" @click="infoVisible = !infoVisible">
              <h2>{{ $__t('결제정보') }}</h2>
              <img
                src="/img/arrow-right@3x.png"
                class="arrow"
                :style="{ transform: `rotate(${infoVisible ? '-90deg' : '90deg'})` }"
              />
            </div>
            <transition name="slide-down">
              <div v-if="infoVisible" ref="info" class="section-body">
                <div class="content">
                  <div class="primary">
                    {{ productTitle }}
                  </div>
                  <div class="secondary" v-html="productDescription" />
                </div>
              </div>
            </transition>
          </div>

          <div class="section booker">
            <div class="section-header">
              <h2>구매하는 사람</h2>
            </div>
            <div class="section-body">
              <ui-form-list>
                <ui-form-list-item>
                  <lug-text-input
                    :placeholder="formData.user_name.placeholder"
                    :type="formData.user_name.type"
                    :label="formData.user_name.label"
                    :error="formData.user_name.error"
                    v-model="formData.user_name.value"
                    :required="true"
                  />
                </ui-form-list-item>
                <ui-form-list-item>
                  <div class="lug-phone">
                    <label>
                      {{ formData.user_phone.label }}
                      <span style="color: red"> * </span>
                      <div class="body">
                        <lug-select
                          :placeholder="formData.user_dial_code.placeholder"
                          :type="formData.user_dial_code.type"
                          :items="formData.user_dial_code.items"
                          v-model="formData.user_dial_code.value"
                        />
                        <lug-text-input
                          :placeholder="formData.user_phone.placeholder"
                          :type="formData.user_phone.type"
                          :inputmode="formData.user_phone.input_mode"
                          v-model="formData.user_phone.value"
                          :mask="formData.user_phone.mask"
                        />
                      </div>
                    </label>
                    <div style="color: rgb(230, 20, 20); font-size: 0.875rem">
                      {{ formData.user_phone.error }}
                    </div>
                  </div>
                </ui-form-list-item>
                <ui-form-list-item>
                  <lug-text-input
                    :placeholder="formData.user_email.placeholder"
                    :type="formData.user_email.type"
                    :label="formData.user_email.label"
                    :error="formData.user_email.error"
                    v-model="formData.user_email.value"
                    :required="true"
                  />
                </ui-form-list-item>
              </ui-form-list>
            </div>
          </div>

          <div class="section pay-method">
            <div class="section-header">
              <h2>{{ $__t('결제수단 선택') | capitalizeFirstLetter }}</h2>
            </div>
            <div class="section-body">
              <div class="method-area">
                <dl
                  v-button
                  @click="
                    $router.push({ name: 'PurchasePayMethods', params: { ...$route.params, order_id: order_id } })
                  "
                >
                  <dt :class="{ required: !formData.pay_method.value }">
                    <template v-if="formData.pay_method.value">
                      {{
                        parseCardName(formData.pay_method.value.card_name) +
                        '   ' +
                        formData.pay_method.value.card_number.substring(
                          formData.pay_method.value.card_number.length - 4
                        )
                      }}
                    </template>
                    <template v-else> {{ $__t('{reserve-order}.pay_method.required') }}</template>
                  </dt>
                  <dd>
                    <span class="text">
                      <template v-if="order.promotion && order.promotion.promotion_id">
                        <template v-if="order.promotion.promotion_type === 'coupon'">
                          {{ coupon && coupon.coupon_name ? coupon.coupon_name : '' }}
                        </template>
                        <template v-if="order.promotion.promotion_type === 'discount-code'">
                          {{ order.promotion.discount_code }}
                        </template>
                      </template>
                      <template v-else-if="formData.pay_method.value"> 카드 변경 </template>
                    </span>
                    <button type="button" class="icon">
                      <img src="/img/arrow-right@3x.png" class="arrow" />
                    </button>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="section price">
            <div class="section-header">
              <h2>결제금액</h2>
            </div>
            <div class="section-body">
              <div class="price-section">
                <dl>
                  <dt class="title">구매금액</dt>
                  <dd class="description">
                    <span class="color--blue">{{ originAmount }}{{ $__t('won') }}</span> 
                  </dd>
                </dl>
                <dl class="secondary">
                  <dt class="title">{{ $__t('쿠폰') | capitalizeFirstLetter }}·{{ $__t('코드') }}</dt>
                  <dd class="description">
                    {{ discountAmount > 0 ? '-' + discountAmount.toLocaleString() : 0 }} {{ $__t('won') }}
                  </dd>
                </dl>
              </div>
              <div class="price-section">
                <dl class="main-item">
                  <dt class="title">총 결제 금액</dt>
                  <dd class="description">
                    <span class="color--blue"><strong>{{ predictedAmount }}</strong> {{ $__t('won') }}</span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="section condition">
            <div class="section-header">
              <h3>{{ $__t('구매조건 확인 및 결제대행 서비스 약관 동의') }}</h3>
              <button v-button type="button" class="text underline" @click="openModal('modalTerms')">
                <span class="text">{{ $__t('보기') }}</span>
              </button>
            </div>
          </div>
          <!-- 
          <div class="section footer">
            <dl class="footer-item">
              <dt class="title">
                <img src="/img/icon/emoji-pouch.svg" />
                <span>{{ $__t("post-pay") }}</span>
              </dt>
              <dd class="description">
                {{ $__t("{reserve-order}.notice.post_pay.description") }}
              </dd>
            </dl>
            <dl class="footer-item">
              <dt class="title">
                <img src="/img/icon/emoji-ticket.svg" />
                <span>{{ $__t("{reserve-order}.notice.expired_ticket.title") }}</span>
              </dt>
              <dd class="description" v-html="$__t('{reserve-order}.notice.expired_ticket.description')"></dd>
            </dl>
            <div class="footer-item">
              {{ $__t("위 주문 내용을 확인 하였으며, 회원 본인은 결제에 동의합니다.") }}
            </div>
          </div> -->

          <div class="button-area">
            <button type="submit" @click="$refs.form.submit()" v-button class="activated">구매하기</button>
          </div>
        </ui-form>
      </div>
    </div>

    <guide-modal id="modal-terms" ref="modalTerms" :noCloseButton="true" :noBgClick="true" :external-close="true">
      <template v-slot:body>
        <agree-terms />
      </template>
    </guide-modal>
  </div>
</template>

<script>
import countryCodes from '@/assets/json/country-codes.json';
import AgreeTerms from '@/components/includes/ReserveOrder/AgreeTerms.vue';
export default {
  name: 'Purchase',

  props: {
    order_id: {
      type: [String, Number],
    },
  },

  data() {
    const AUTH_DATA = this.$store.state.auth;

    return {
      formData: {
        user_name: {
          type: 'text',
          label: this.$__t('성명'),
          value: AUTH_DATA.user_name || AUTH_DATA.user_last_name + ' ' + AUTH_DATA.user_first_name,
          validation: [{ type: 'required' }],
          error: '',
        },
        user_dial_code: {
          value: AUTH_DATA.user_dial_code || '+82',
          error: '',
          validation: [{ type: 'required' }],
          items: this.parseCountryCodes(countryCodes),
          label: this.$__t('국가 번호'),
        },
        user_phone: {
          type: 'text',
          label: this.$__t('휴대폰 번호'),
          placeholder: this.$__t('휴대폰 번호를 입력해주세요'),
          validation: [{ type: 'required' }, { type: 'number' }],
          input_mode: 'number',
          error: '',
          value: AUTH_DATA.user_phone,
          mask: '###-####-####',
        },
        user_email: {
          type: 'text',
          label: this.$__t('이메일 주소'),
          validation: [{ type: 'required' }, { type: 'email' }],
          value: AUTH_DATA.user_email,
          error: '',
        },
        coupon_code: {
          value: '',
        },
        discount_code: {
          value: '',
        },
        pay_method: {
          items: { label: this.$__t('Credit Card'), value: 'card' },
          value: this.primaryBilling,
          validation: [{ type: 'required' }],
        },
      },

      infoVisible: false,
      listQuery: {
        payMethod: {
          page: 1,
          size: 10,
        },
      },
    };
  },

  computed: {
    order() {
      return this.$store.getters['orders/GET_ITEM']({ key: 'reserve_id', value: this.order_id });
    },

    purchaseFormState() {
      const PURCHASE_FORM_STATE = this.$store.getters['resourceForm/GET_ITEM']({
        key: 'form_id',
        value: 'purchase_form',
      });

      this.formData.pay_method.value = PURCHASE_FORM_STATE.pay_method;

      return PURCHASE_FORM_STATE;
    },

    productTitle() {
      return this.order?.product_data?.product_name || '';
    },

    productDescription() {
      return '';
    },

    originAmount() {
      return (this.order?.product_data?.price || 0).toLocaleString();
    },

    predictedAmount() {
      return (this.order?.predicted_amount || 0).toLocaleString();
    },

    productFormState() {
      const PRODUCT_FORM_STATE = this.$store.getters['resourceForm/GET_ITEM']({
        key: 'form_id',
        value: 'product_form',
      });
      this.formData.pay_method.value = PRODUCT_FORM_STATE.pay_method;

      return PRODUCT_FORM_STATE;
    },

    billings() {
      return Object.keys(this.$store.state.billings.items)
        .map((o) => this.$store.state.billings.items[o])
        .sort((a, b) => {
          const A_CREATD_AT = this.$moment(a.created_at);
          const B_CREATD_AT = this.$moment(b.created_at);

          if (A_CREATD_AT.isBefore(B_CREATD_AT)) {
            return 1;
          }

          if (B_CREATD_AT.isBefore(A_CREATD_AT)) {
            return -1;
          }

          return 0;
        });
    },

    primaryBilling() {
      return this.billings.find((billing) => billing.is_primary > 0);
    },
  },

  async mounted() {
    let data = await this.getOrder();

    try {
      if (data.status === 'PAID') {
        this.$store.commit('loading/SET_TRUE');

        return setTimeout(() => {
          this.$router.replace('/');
          this.$store.commit('loading/SET_FALSE');
        }, 500);
      }
    } catch (e) {}

    this.setPurchaseFormState(data);

    this.getPayMethods()
      .then(() => this.loadFormStateData())
      .catch(() => {});
  },

  methods: {
    getOrder() {
      return this.$store.dispatch('orders/reserve', { id: this.order_id });
    },

    parseCountryCodes(countryCodes) {
      let _dial_code = countryCodes.map((o) => ({ label: `${o.dial_code} (${o.code})`, value: o.dial_code })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        if (o.name === 'Korea, Republic of') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        if (o.name === 'Others') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },

    openModal(ref) {
      return this.$refs[ref].open();
    },

    closeModal(ref) {
      return this.$refs[ref].close();
    },

    setPurchaseFormState(res = {}) {
      return this.$store.commit('resourceForm/SET_ITEM', {
        data: {
          form_id: 'purchase_form',
        },
      });
    },

    loadFormStateData() {
      let { user_name, user_dial_code, user_phone, user_email, coupon_code, discount_code, pay_method } =
        this.purchaseFormState || {};

      try {
        this.formData.user_name.value = user_name || this.formData.user_name.value;
        this.formData.user_dial_code.value = user_dial_code || this.formData.user_dial_code.value;
        this.formData.user_phone.value = user_phone || this.formData.user_phone.value;
        this.formData.user_email.value = user_email || this.formData.user_email.value;
        this.formData.coupon_code.value = coupon_code || this.formData.coupon_code.value;
        this.formData.discount_code.value = discount_code || this.formData.discount_code.value;
        this.formData.pay_method.value = pay_method || this.primaryBilling || this.formData.pay_method.value;
      } catch (e) {}
    },

    async getPayMethods() {
      try {
        return this.$store.dispatch('billings/getCardList', { query: this.listQuery.payMethod });
      } catch (e) {}
    },

    parseCardName(cardName = '') {
      const regExp = /\[([^)]+)\]/;

      let matches = regExp.exec(cardName);

      if (Array.isArray(matches) && matches.length > 0) {
        return matches[1];
      }

      return cardName;
    },

    async purchaseComplete(data = {}) {
      this.$store.commit('loading/SET_TRUE');
      try {
        await this.$store.dispatch('orders/purchaseComplete', {
          id: this.order_id,
          reserve: data.reserve,
          billing: data.billing,
        });

        this.$router.push({ name: 'PurchaseComplete', params: { order_id: this.order_id } });
      } catch (e) {
        this.$store.commit('loading/SET_FALSE');
        this.$store.commit('alert/ADD_ITEM', { message: JSON.stringify(e || {}), status: 'error' });
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    submitForm(values) {
      let { pay_method, user_email, user_name, user_phone } = values || {};
      pay_method = this.$utils.parseJSON(pay_method);

      const USER_DATA = {
        user_name,
        user_phone,
        user_email,
      };

      let data = {
        reserve: JSON.stringify(USER_DATA),
        billing: JSON.stringify({
          billing_id: pay_method.uid,
          ...USER_DATA,
        }),
      };

      return this.purchaseComplete(data);
    },
  },

  components: {
    AgreeTerms,
  },
};
</script>
